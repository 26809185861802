@use 'src/app/variables' as *;

.authLayout {
  margin: 0 auto;
  padding: var(--io-spacing-12) var(--io-spacing-6);
  max-width: toRem(480);
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--io-spacing-6);
}

.logo {
  position: relative;
  display: block;
  width: toRem(184);
  height: toRem(72);
  margin-bottom: var(--io-spacing-5);
}

.description {
  color: var(--io-text-dimmed-700);
  margin-top: var(--io-spacing-2);
  margin-bottom: 0;
}

.alertContainer {
  margin-bottom: var(--io-spacing-6);
}
