.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6 {
  font-weight: 600;
  line-height: var(--io-line-height-heading);
  font-family: var(--font-io);
}

.heading1 {
  font-size: var(--io-font-size-heading-1);
}

.heading2 {
  font-size: var(--io-font-size-heading-2);
}

.heading3 {
  font-size: var(--io-font-size-heading-3);
}

.heading4 {
  font-size: var(--io-font-size-heading-4);
}

.heading5 {
  font-size: var(--io-font-size-heading-5);
}

.heading6 {
  font-size: var(--io-font-size-heading-6);
}

.subheading1 {
  font-size: var(--io-font-size-subheading-1);
  line-height: var(--io-line-height-heading);
  font-weight: 600;
}

.subheading2 {
  font-size: var(--io-font-size-subheading-2);
  line-height: var(--io-line-height-heading);
  font-weight: 500;
}

.section {
  font-size: var(--io-font-size-section-section-heading);
  line-height: var(--io-line-height-heading);
  font-weight: 600;
  font-family: var(--font);
}
