.authForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.authFormFields {
  margin-bottom: var(--io-spacing-6);
  width: 100%;
}

.authFormGroup {
  width: 100%;
}
.authFormGroup:not(:last-child) {
  margin-bottom: var(--io-spacing-4);
}

.authFormFooter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: var(--io-spacing-4);
  gap: var(--io-spacing-4);
}
